import React from "react" 
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils";

import Button from '@bit/azheng.joshua-tree.button';
import CollapseRow from "../components/CollapseRow"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const FAQPage = ({ data, pageContext, location }) => {

  var post;
  var language;
  let postObj = getPostObj(pageContext, data.allUniquePagesJson.nodes[0], data.allSpanishUniquePagesJson.nodes[0]);
  post = postObj.post;
  language = postObj.language;

  return (
  <SharedStateProvider>
    <Layout language={language}>
    <SEO title={post.metaTitle} description={post.metaDescription} pathname={location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
    <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
        />
      <div className="joshua-tree-content">
        <div className="columns top-section text-section white-back" style={{ paddingBottom: "0" }}>
          <div className="column is-6"></div>
          <div className="column is-12">
            <div>
              <h1>{post.heading}</h1>
            </div>
          </div>
        </div>

        <section className="body-sections section collapse-rows faq-body" style={{ paddingTop: "34px" }}>
          {post.collapseRows.map((row, i) => 
                  <CollapseRow
                  key={row.collapseRow.heading}
                  isOpen={i === 0}
                  heading={<h2 class='h5-style'>{row.collapseRow.heading}</h2>}
                  paragraphs={<div dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(row.collapseRow.text)
                  )}></div>}
                />  
          )}
        </section>
      </div>
    </Layout>
  </SharedStateProvider>
)}

export const pageQuery = graphql`
query FAQPage ($title: String!)  {
  allUniquePagesJson(filter: {title: {eq: $title}}) {
    nodes {
      title
      heading
      metaTitle
      metaDescription
      schemaPage
      collapseRows {
        collapseRow {
          heading
          text
        }
      }
    }
  }
  allSpanishUniquePagesJson(filter: {title: {eq: $title}}) {
    nodes {
      title
      heading
      metaTitle
      schemaPage
      metaDescription
      collapseRows {
        collapseRow {
          heading
          text
        }
      }
    }
  }
}

`

export default FAQPage
